<template>
  <section>
    <transition name="zoom-fade" mode="out-in">
      <router-view :key="$route.fullPath" />
    </transition>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data: () => ({}),
  computed: {
    ...mapState('captures', ['item']),
    captureTitle() {
      if (this.isMounted) return this.item.title;
      return null;
    }
  },
  methods: {
    async getCapture() {
      return await this.$store
        .dispatch('captures/item', {
          id: this.$route.params.id
        })
        .catch(() => {
          this.$router.push('not-found');
        });
    }
  },
  beforeCreate() {},
  async created() {
    await this.getCapture();
  },
  mounted() {},
  watch: {
    '$route.params.id'() {
      this.getCapture();
    }
  }
};
</script>

<style lang="scss">
.capture-view-area {
  .vs-popup {
    width: 700px !important;
    max-width: 700px !important;
  }

  hr {
    border-top: 1px solid #dfdfdf;
    margin: 20px 0;
  }
  pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    color: #666;
    page-break-inside: avoid;
    font-family: 'Courier New', Courier, monospace;
    font-size: 13px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }

  code {
    background: transparent;
    padding: 0.1rem 0.3rem;
    border-radius: 0;
  }

  // .vs-progress--background {
  //   margin-top: 0rem !important;
  //   height: 20px !important;
  // }

  .capture-header {
    display: flex;
    margin-top: 0rem;
    border-top: 1px solid #dae1e7;
    border-bottom: 1px solid #dae1e7;
    justify-content: space-between;
    padding: 6px 0;
    align-items: center;
    p {
      margin: 5px 0;
    }
  }

  .capture-body {
    // display: flex;
    // padding: 40px 0;
    // justify-content: space-between;
    // flex-flow: row wrap;
    .capture-content {
      // flex: 1 1 70%;
      // width: 70%;
      img {
        // display: block;
        // width: 100%;
      }
      .vs-tabs--content {
        padding: 10px 0;
      }
    }

    .capture-sidebar {
      // flex: 1 1 30%;
      // padding-left: 2rem;
      // width: 30%;
    }
    .capture-sidebar__header {
      // height: 7rem;
      // border-bottom: 1px solid #dae1e7;
      // padding: 10px;
      // p {
      //   padding: 0px 0 4px 0;
      //   line-height: 1;
      // }
    }
  }

  .vs-tabs--li button.vs-tabs--btn {
    padding: 12px 20px;
  }
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      // border-collapse: separate;
      // border-spacing: 0 1.3rem;
      padding: 0;
      img {
        max-width: 150px;
      }
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .con-vs-tabs {
    overflow: visible;
    .con-slot-tabs {
      overflow: inherit;
    }
  }

  .vx-card {
    &.no-gutter {
      .vx-card__body {
        padding: 0;
      }
    }
  }
}
</style>
